const user = {
    state: {
        uid: 0,
        accessToken: '',
        tokenTime: 0
    },
    mutations: {
        USER_INFO(state, info) {
            state.uid = info
        },
        USER_ACCESS_TOKEN(state, token) {
            state.accessToken = token
        },
        USER_TOKEN_TIME(state, time) {
            state.tokenTime = time
        }
    },
    actions: {
        saveUserInfo({ commit }, data) {
            commit('USER_INFO', data)
        },
        saveAccessToken({ commit }, data) {
            commit('USER_ACCESS_TOKEN', data)
        },
        saveTokenTime({ commit }, data) {
            commit('USER_TOKEN_TIME', data)
        }
    }
    ,
    getters: {
        isLogin(state) {
            return state.uid && state.uid > 0
        },
        getUid(state) {
            return state.uid
        },
        getAccessToken(state) {
            return state.accessToken
        },
        getTokenTime(state) {
            return state.tokenTime
        }
    }
};
export default user