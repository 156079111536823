import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
    },
    {
        path: '/study',
        name: 'study',
        component: () => import('../views/StudyView.vue')
    },
    {
        path: '/bf',
        name: 'bf',
        component: () => import('../views/BFHomeView.vue')
    },
    {
        path: '/tv',
        name: 'tv',
        component: () => import('../views/TestView.vue')
    },
    {
        path: '/tsv',
        name: 'tsv',
        component: () => import('../views/TestSocketView.vue')
    },
    {
        path: '/tbv',
        name: 'tbv',
        component: () => import('../views/TestBaiduView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
